import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import { useConfiguration } from '../../../../context/configurationContext';
import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';
import LandingSearchFilterForm from './LandingSearchFilterForm/LandingSearchFilterForm';
import { useState } from 'react';
import { USER_TYPE_CUSTOMER, USER_TYPE_VENDOR } from '../../../../util/types';
import TermsAndConditions from '../../../AuthenticationPage/TermsAndConditions/TermsAndConditions';
import { TermsOfServiceContent } from '../../../TermsOfServicePage/TermsOfServicePage';
import { PrivacyPolicyContent } from '../../../PrivacyPolicyPage/PrivacyPolicyPage';
import { Modal } from '../../../../components';
import { camelize } from '../../../../util/string';
import {
  PRIVACY_POLICY_ASSET_NAME,
  TOS_ASSET_NAME,
} from '../../../AuthenticationPage/AuthenticationPage.duck';
import { FormattedMessage, useIntl } from 'react-intl';
import { isSignupEmailTakenError } from '../../../../util/errors';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;
 
  const currentUser = options.currentUser;

  const isVendor =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.userRole == USER_TYPE_VENDOR;

  const intl = useIntl();
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const config = useConfiguration();
  const [isModalOpen, setIsOpenModal] = useState(false);
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(options.signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );
  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = errorMessage(options.signupError, signupErrorMessage);

  const handleSubmitSignup = async values => {
    const { fullName, serviceType = [], ...rest } = values;

    const restValues =
      serviceType && serviceType.length
        ? { ...rest, serviceType, serviceTypeCategory: serviceType.map(st => st.option) }
        : { ...rest, serviceType };

    const name = fullName.trim().split(' ');
    const params = {
      firstName: name[0],
      lastName: `${name.length > 1 ? name[name.length - 1] : ' '} `,
      userRole: USER_TYPE_CUSTOMER,
      transactionProcessAlias: 'default-purchase/release-1',
      ...restValues,
    };
    try {
      const response = await options.submitSignup(params);
      if (response == true) {
        return response;
      }
    } catch (error) {
      console.log('signup failed');
    }
  };

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field
            data={isVendor ? null : title}
            className={defaultClasses.title}
            options={fieldOptions}
          />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          {sectionId == 'landing-hero' && (
            <LandingSearchFilterForm
              onSubmit={values => {}}
              initialValues={null}
              isMobile={false}
              appConfig={config}
              options={options}
              isModalOpen={isModalOpen}
              setIsOpenModal={setIsOpenModal}
              onSubmitLandingSearchFilterForm={values => handleSubmitSignup(values)}
              currentUser={options.currentUser}
              isAuthenticated={options.isAuthenticated}
              signupInProgress={options.signupInProgress}
              signupSuccess={options.signupSuccess}
              onCustomerListing={options.onCustomerListing}
              onOtpSignUpCheck={options.onOtpSignUpCheck}
              getOtpSignup={options.getOtpSignup}
              termsAndConditions={
                <TermsAndConditions
                  onOpenTermsOfService={() => setTosModalOpen(true)}
                  onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
                  intl={intl}
                />
              }
              loginOrSignupError={loginOrSignupError}
              otpData={options.otpData}
              otpInProgress={options.otpInProgress}
              otpCheckData={options.otpCheckData}
              fetchVendorsInProgress={options.fetchVendorsInProgress}
              otpCheckError={options.otpCheckError}
              doesPhoneExist={options.doesPhoneExist}
              onCheckPhoneNumber={options.onCheckPhoneNumber}
              otpCheckInProgress={options.otpCheckInProgress}
              onUpdateProfile={options.onUpdateProfile}
              updateInProgress={options.updateInProgress}
              loginInProgress={options.loginInProgress}
            />
          )}
          {sectionId !== 'section-1' && (
            <Field
              data={callToAction}
              className={defaultClasses.ctaButton}
              options={fieldOptions}
            />
          )}
        </header>
      ) : null}
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={() => {}}
      >
        <div className={css.termsWrapper}>
          <TermsOfServiceContent
            inProgress={options.tosFetchInProgress}
            error={options.tosFetchError}
            data={options.tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
      <Modal
        id="AuthenticationPage.privacyPolicy"
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        usePortal
        onManageDisableScrolling={() => {}}
      >
        <div className={css.privacyWrapper}>
          <PrivacyPolicyContent
            inProgress={options.tosFetchInProgress}
            error={options.tosFetchError}
            data={options.tosAssetsData?.[camelize(PRIVACY_POLICY_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
