import React, { useEffect, useRef, useState } from 'react';
import { bool, node, func, object, string, array } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../../../util/reactIntl';
import * as validators from '../../../../../util/validators';
import {
  Form,
  FieldTextInput,
  Button,
  NamedLink,
  Modal,
  FieldSelect,
  FieldLocationAutocompleteInput,
  FieldRadioButton,
  FieldCheckboxGroup,
  IconSpinner,
} from '../../../../../components';
import css from './LandingModalForm.module.css';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
} from '../../../../../util/validators';
import IconSearchDesktop from './IconSearchDesktop';
import {
  categories,
  allSubCtegories,
  eventTypePlanning,
  eventBudget,
  hiringDecision,
  specificCulture,
} from '../../../../../config/configData';
import { FIELD_TYPE_RADIO, USER_TYPE_CUSTOMER, USER_TYPE_VENDOR } from '../../../../../util/types';
import MainPanelLanding from '../../../../SearchPage/MainPanelLanding';
import PhoneInput from 'react-phone-input-2';

const identity = v => v;

const LandingModalFormComponent = props => {
  if (typeof window === 'undefined') return null;
  require('react-phone-input-2/lib/style.css');
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          autoFocus,
          values,
          formId,
          form,
          options,
          setIsOpenModal,
          isModalOpen,
          currentUser,
          isAuthenticated,
          termsAndConditions,
          loginOrSignupError,
          signupInProgress,
          signupSuccess,
          onCustomerListing,
          getOtpSignup,
          onOtpSignUpCheck,
          otpData,
          otpInProgress,
          otpCheckData,
          otpCheckError,
          otpCheckInProgress,
          onCheckPhoneNumber,
          doesPhoneExist,
          onManageDisableScrolling,
          openLandingModal,
          setOpenLandingModal,
          backgroundImage,
          media,
          onUpdateProfile,
          updateInProgress,
          loginInProgress,
          fetchVendorsInProgress
        } = fieldRenderProps;

        const verifyOtp = otpCheckData?.status == 'approved';
        const verifyOtpError = otpCheckData?.valid == false;
        const checkPhoneNumber = doesPhoneExist?.status == 'user Exist';
        const vendorPhoneNumber = currentUser?.attributes?.profile?.publicData?.phoneNumber;

        const isVendor =
          currentUser &&
          currentUser.attributes &&
          currentUser.attributes.profile &&
          currentUser.attributes.profile.publicData &&
          currentUser.attributes.profile.publicData.userRole == USER_TYPE_VENDOR;

        const classes = classNames(rootClassName, css.root, className);

        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const submitInProgress = inProgress;
        const serviceType = categories.filter(st => st.option == openLandingModal?.title);

        const filteredData = serviceType && serviceType?.map(st => st.label);

        const serviceTypeArray = serviceType && Array.isArray(serviceType) ? serviceType : [];

        const submitDisableGenricQuestions = !(
          values?.location &&
          values?.specificCulture &&
          values?.eventTypePlanning &&
          values?.eventBudget &&
          values?.eventHiringDecision &&
          ((values?.eventTypePlanning !== 'OtherEventTypePlanning' ||
            values?.OtherEventTypePlanning) &&
            (values?.eventBudget !== 'OtherEventBudget' || values?.OtherEventBudget) &&
            (values?.eventHiringDecision !== 'OtherHiringDecision' ||
              values?.OtherEventHiringDecision))
        );
        const isDateSelected = values?.date || values?.dateNotconfirmed;
        const [currentDate, setCurrentDate] = useState('');

        useEffect(() => {
          const today = new Date().toISOString().split('T')[0];
          setCurrentDate(today);
        }, []);

        const listingParams = {
          title: currentUser?.attributes?.profile?.firstName,
          publicData: {
            eventBudget: values?.eventBudget,
            OtherEventBudget: values?.OtherEventBudget,
            eventDate: values?.eventDate,
            dateNotconfirmed: values?.dateNotconfirmed,
            eventHiringDecision: values?.eventHiringDecision,
            OtherEventHiringDecision: values?.OtherEventHiringDecision,
            eventTypePlanning: values?.eventTypePlanning,
            OtherEventTypePlanning: values?.OtherEventTypePlanning,
            serviceType: serviceType,
            serviceTypeValues: values?.serviceTypeValues,
            serviceTypeCategory: serviceType?.map(st => st?.option),
            location: values?.location,
            specificCulture: values?.specificCulture,
            OtherSpecificCulture: values?.OtherSpecificCulture,
            userRole: USER_TYPE_CUSTOMER,
            transactionProcessAlias: 'default-purchase/release-1',
          },
        };

        const selectedServices = serviceTypeArray;
        const selectedServicesType = values?.serviceTypeValues || {};

        const checkIfValuesSelected = () => {
          if (selectedServices?.length !== Object.keys(selectedServicesType).length) return true;

          const serviceNotSelected = selectedServices?.filter(service => {
            const { label, subCategoriesArray } = service;
            const currentServiceObject = selectedServicesType[label] || {};

            let requiredCount = subCategoriesArray.length;
            let otherFieldsFilled = true;

            // Check for "Other" fields
            subCategoriesArray.forEach(subCategory => {
              const subCategoryValue = currentServiceObject[subCategory.value];
              if (subCategoryValue && subCategoryValue.includes('Other')) {
                const otherFieldKey = `${subCategory.value}Other`;
                if (currentServiceObject[otherFieldKey]) {
                  requiredCount += 1;
                } else {
                  otherFieldsFilled = false;
                }
              }
            });

            return Object.keys(currentServiceObject).length !== requiredCount || !otherFieldsFilled;
          });

          return serviceNotSelected?.length > 0;
        };

        const submitDisabled = checkIfValuesSelected();

        useEffect(() => {
          if (values?.dateNotconfirmed) {
            form.change('date', '');
          }
        }, [values?.dateNotconfirmed]);

        const [phoneNumber, setPhoneNumber] = useState({
          value: '',
          countryCode: '',
          country: '',
          number: '',
        });

        const handleOnChange = (value, country) => {
          const { name = '', dialCode = '', countryCode = '', format } = country;
          form.change('phoneNumber', '+' + value);
          form.change('dialCode', dialCode);
          setPhoneNumber({
            value: value,
            countryCode: countryCode,
            countryName: name,
            dialCode: dialCode,
          });

          let filterLength = 0;
          for (let i = 0; i < format?.length; i++) {
            if (format[i] == '.') {
              filterLength += 1;
            }
          }

          if (value && filterLength && filterLength == value.length) {
            onCheckPhoneNumber('+' + value);
          }
        };

        const [otp, setOTP] = useState(['', '', '', '', '', '']);
        const otpFields = useRef([]);
        const [otpTimer, setOtpTimer] = useState(59);
        const intervalRef = useRef(null);

        const handleChange = (index, value) => {
          const updatedOTP = [...otp];
          updatedOTP[index] = value;
          setOTP(updatedOTP);
          if (value !== '' && index < 5) {
            otpFields.current[index + 1].focus();
          }
        };

        const handleKeyPress = (index, e) => {
          if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            e.preventDefault();
            setTimeout(() => otpFields.current[index - 1].focus(), 0);
          }
        };

        const sendOTPAfterTimer = () => {
          intervalRef.current = setInterval(() => {
            setOtpTimer(prevValue => {
              if (prevValue === 0) {
                clearInterval(intervalRef.current);
                return 0;
              } else {
                return prevValue - 1;
              }
            });
          }, 1000);
        };
        const handleSignupUser = async () => {
          try {
            if (!isAuthenticated) {
              props.onSubmitLandingSearchFilterForm(values).then(async signupResponse => {
                if (signupResponse == true) {
                  getOtpSignup({ phoneNumber: values?.phoneNumber }).then(async response => {
                    if (response?.valid === false) {
                      setOpenLandingModal({ ...openLandingModal, modalNumber: 'seven' });
                      sendOTPAfterTimer();
                    }
                  });
                }
              });
            } else {
              getOtpSignup({ phoneNumber: values?.phoneNumber }).then(async response => {
                if (response?.valid === false) {
                  setOpenLandingModal({ ...openLandingModal, modalNumber: 'seven' });
                  sendOTPAfterTimer();
                }
                const profile = {
                  publicData: {
                    phoneNumber: values?.phoneNumber,
                  },
                };
                onUpdateProfile(profile);
              });
            }
          } catch (error) {
            console.error('Please try after some time', error);
          }
        };
        
        return (
          <Form className={classes}>
            <Modal
              className={
                openLandingModal.modalNumber == 'one'
                  ? css.firstLandingModal
                  : css.secondLandingModal
              }
              id="VerifyDeletingPaymentMethod"
              isOpen={!!openLandingModal?.modalNumber}
              onClose={() => {
                form.restart()
                setOpenLandingModal({});
              }}
              onManageDisableScrolling={() => {}}
            >
              <div className={css.modalBody}>
                {openLandingModal.modalNumber == 'one' ? (
                  <div className={css.mediaWrapper}>
                    <div
                      className={
                        openLandingModal.modalNumber == 'one'
                          ? css.firstPageLayout
                          : css.modalSearchWrapper
                      }
                      style={{
                        backgroundImage: `url(${backgroundImage})`,
                      }}
                    >
                      <FieldLocationAutocompleteInput
                        rootClassName={css.locationAddress}
                        inputClassName={css.locationAutocompleteInput}
                        iconClassName={css.locationAutocompleteInputIcon}
                        predictionsClassName={css.predictionsRoot}
                        validClassName={css.validLocation}
                        autoFocus={autoFocus}
                        name="location"
                        placeholder={intl.formatMessage({
                          id: 'EditListingLocationForm.signupAddressPlaceholder',
                        })}
                        useDefaultPredictions={false}
                        format={identity}
                        valueFromForm={values.location}
                        validate={composeValidators(
                          autocompleteSearchRequired(addressRequiredMessage),
                          autocompletePlaceSelected(addressNotRecognizedMessage)
                        )}
                      />
                      <div className={css.bottomButton}>
                        <Button
                          className={css.submitButton}
                          type="button"
                          onClick={e => {
                            e.preventDefault();
                            setOpenLandingModal({ ...openLandingModal, modalNumber: 'two' });
                          }}
                          disabled={!values?.location?.selectedPlace}
                        >
                          Explore Services
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : openLandingModal.modalNumber == 'two' ? (
                  <div>
                    {serviceTypeArray.length
                      ? serviceTypeArray.map((st, i) => {
                          const { option, label, subCategoriesArray = [] } = st;
                          return (
                            <div key={label + i}>
                              <h4 className={css.modalTitle}>{label}</h4>
                              {subCategoriesArray.length
                                ? subCategoriesArray.map((item, subIndex) => {
                                    const { type, value, text } = item;
                                    const name = `serviceTypeValues[${option}][${value}]`;
                                    const otherValue = `serviceTypeValues[${option}][${value +
                                      'Other'}]`;
                                    const eachSubCategory = allSubCtegories[value];

                                    if (type == FIELD_TYPE_RADIO) {
                                      return (
                                        <div key={value + label}>
                                          <p className={css.modalText}>{text}</p>
                                          {eachSubCategory.map(stItem => {
                                            return (
                                              <FieldRadioButton
                                                id={stItem.option}
                                                key={stItem.label + i + label}
                                                value={stItem.option}
                                                label={stItem.label}
                                                name={name}
                                                className={css.radioBtnInput}
                                                validate={required}
                                              />
                                            );
                                          })}

                                          {values &&
                                          values.serviceTypeValues &&
                                          values.serviceTypeValues[option] &&
                                          values.serviceTypeValues[option][value] &&
                                          values.serviceTypeValues[option][value].includes(
                                            'Other'
                                          ) ? (
                                            <FieldTextInput
                                              type="text"
                                              name={otherValue}
                                              validate={required}
                                            />
                                          ) : null}
                                        </div>
                                      );
                                    }
                                    return null;
                                  })
                                : null}
                            </div>
                          );
                        })
                      : null}
                    <div className={css.functionButtons}>
                      <Button
                        type="button"
                        onClick={() =>
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'one' })
                        }
                        className={css.singleBackBtn}
                      >
                        Back
                      </Button>
                      <Button
                        type="button"
                        onClick={() =>
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'three' })
                        }
                        className={css.singleNextBtn}
                        disabled={submitDisabled}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                ) : openLandingModal.modalNumber == 'three' ? (
                  <div>
                    <div>
                      <p className={css.modalText}>What date is the event?</p>
                      <div>
                        <FieldTextInput
                          className={css.dateInput}
                          type="date"
                          id={formId ? `${formId}.date` : 'date'}
                          name="date"
                          min={currentDate}
                          label={intl.formatMessage({
                            id: 'SignupForm.datelLabel',
                          })}
                          disabled={values?.dateNotconfirmed}
                        />
                      </div>

                      <FieldCheckboxGroup
                        name="dateNotconfirmed"
                        id="dateNotconfirmed"
                        optionLabelClassName={css.finePrint}
                        className={css.contactCheckbox}
                        options={[{ option: "I'm not sure yet", label: "I'm not sure yet" }]}
                      />
                    </div>
                    <div>
                      <p className={css.modalText}>Where is the location of the event?</p>
                      <FieldLocationAutocompleteInput
                        className={css.locationBox}
                        rootClassName={css.locationAddressForm}
                        inputClassName={css.locationAutocompleteInput}
                        iconClassName={css.locationAutocompleteInputIcon}
                        predictionsClassName={css.predictionsRoot}
                        validClassName={css.validLocation}
                        autoFocus={autoFocus}
                        name="location"
                        label={intl.formatMessage({ id: 'EditListingLocationForm.address' })}
                        placeholder={intl.formatMessage({
                          id: 'EditListingLocationForm.addressPlaceholder',
                        })}
                        useDefaultPredictions={false}
                        format={identity}
                        valueFromForm={values.location}
                        validate={composeValidators(
                          autocompleteSearchRequired(addressRequiredMessage),
                          autocompletePlaceSelected(addressNotRecognizedMessage)
                        )}
                      />
                    </div>
                    <div>
                      <div>
                        <p className={css.modalText}>
                        What specific cultural considerations do you have for this event?
                        </p>
                      </div>
                      <div>
                        <FieldSelect
                          className={css.inputBox}
                          id="specificCulture"
                          name="specificCulture"
                          label={intl.formatMessage({
                            id: 'SignupForm.specificCultureLabel',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'SignupForm.specificCulturePlaceholder',
                          })}
                        >
                          <option disabled value="">
                            Select any one
                          </option>
                          {specificCulture &&
                            specificCulture.length &&
                            specificCulture.map(item => (
                              <option key={item.option} value={item.option}>
                                {item.label}
                              </option>
                            ))}
                        </FieldSelect>
                      </div>
                      <div>
                        {values?.specificCulture == 'OtherCulture' && (
                          <>
                            <br />
                            <FieldTextInput
                              type="text"
                              id="OtherSpecificCulture"
                              name="OtherSpecificCulture"
                              label={'Other Specific Culture'}
                              placeholder={'Type here...'}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <div>
                        <p className={css.modalText}> What type of event are you planning? </p>
                        <FieldSelect
                          className={css.inputBox}
                          id="eventTypePlanning"
                          name="eventTypePlanning"
                        >
                          <option disabled value="">
                            Select any one
                          </option>
                          {eventTypePlanning &&
                            eventTypePlanning.length &&
                            eventTypePlanning.map(item => (
                              <option key={item.option} value={item.option}>
                                {item.label}
                              </option>
                            ))}
                        </FieldSelect>
                      </div>
                      <div>
                        {values?.eventTypePlanning === 'OtherEventTypePlanning' && (
                          <>
                            <br />
                            <FieldTextInput
                              type="text"
                              id="OtherEventTypePlanning"
                              name="OtherEventTypePlanning"
                              label={'Other Event type'}
                              placeholder={'Type here...'}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <div>
                        <p className={css.modalText}>
                          What is the approximate budget for your event?
                        </p>
                        <FieldSelect className={css.inputBox} id="eventBudget" name="eventBudget">
                          <option disabled value="">
                            Select any one
                          </option>
                          {eventBudget &&
                            eventBudget.length &&
                            eventBudget.map(item => (
                              <option key={item.option} value={item.option}>
                                {item.label}
                              </option>
                            ))}
                        </FieldSelect>
                      </div>
                      {values?.eventBudget === 'OtherEventBudget' && (
                        <>
                          <br />
                          <FieldTextInput
                            type="text"
                            id="OtherEventBudget"
                            name="OtherEventBudget"
                            label={'If other, please specify'}
                            placeholder={'Type here...'}
                          />
                        </>
                      )}
                    </div>
                    <div>
                      <div>
                        <p className={css.modalText}>
                          {' '}
                          How likely are you to make a hiring decision?
                        </p>
                        <FieldSelect
                          className={css.inputBox}
                          id="eventHiringDecision"
                          name="eventHiringDecision"
                        >
                          <option disabled value="">
                            Select any one
                          </option>
                          {hiringDecision &&
                            hiringDecision.length &&
                            hiringDecision.map(item => (
                              <option key={item.option} value={item.option}>
                                {item.label}
                              </option>
                            ))}
                        </FieldSelect>
                      </div>
                      {values?.eventHiringDecision === 'OtherHiringDecision' && (
                        <>
                          <br />
                          <FieldTextInput
                            type="text"
                            id="OtherEventHiringDecision"
                            name="OtherEventHiringDecision"
                            label={'Other Event Hiring Decision'}
                            placeholder={'Type here...'}
                          />
                        </>
                      )}
                    </div>
                    <div className={css.functionButtons}>
                      <Button
                        type="button"
                        onClick={() =>
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'two' })
                        }
                        className={css.singleBackBtn}
                      >
                        Back
                      </Button>
                      <Button
                        type="button"
                        onClick={() =>
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'four' })
                        }
                        className={css.singleNextBtn}
                        disabled={submitDisableGenricQuestions}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                ) : openLandingModal.modalNumber == 'four' ? (
                  <div>
                    <div className={css.successBg}>
                      <div className={css.successMark}>
                        <svg
                          width="50px"
                          height="50px"
                          viewBox="0 0 1.5 1.5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.21 0.559A0.469 0.469 0 0 1 0.559 0.21c0.125 -0.029 0.256 -0.029 0.381 0a0.469 0.469 0 0 1 0.35 0.35c0.029 0.125 0.029 0.256 0 0.381a0.469 0.469 0 0 1 -0.35 0.35c-0.125 0.029 -0.256 0.029 -0.381 0a0.469 0.469 0 0 1 -0.35 -0.35 0.835 0.835 0 0 1 0 -0.381Z"
                            stroke="#363853"
                            strokeWidth="0.09375"
                          />
                          <path
                            d="m0.578 0.734 0.125 0.125 0.219 -0.234"
                            stroke="#363853"
                            strokeWidth="0.09375"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <h2 className={css.modalTextCenter}>
                        Great! We've found you the perfect matches.
                      </h2>
                      <p className={css.description}>
                        Lastly, we need your details to attach to your request.
                      </p>
                    </div>
                    <div className={css.functionButtons}>
                      <Button
                        type="button"
                        onClick={() => {
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'three' });
                        }}
                        className={css.singleBackBtn}
                      >
                        Back
                      </Button>
                      <Button
                        type="button"
                        onClick={async () => {
                          if (!isAuthenticated) {
                            setOpenLandingModal({ ...openLandingModal, modalNumber: 'five' });
                          } else {
                            try {
                              const response = await onCustomerListing(listingParams);
                              if (response == 'true') {
                                setOpenLandingModal({ ...openLandingModal, modalNumber: 'eight' });
                              } else throw new Error('Response is not defined');
                            } catch (error) {
                              console.error('Error in onCustomerListing:', error);
                            }
                          }
                        }}
                        className={css.singleNextBtn}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                ) : openLandingModal.modalNumber == 'five' ? (
                  <div className={css.fifthWrapper}>
                    <h4 className={css.modalTitle}>
                      What email address would you like quotes sent to?
                    </h4>
                    <div>
                      <FieldTextInput
                        type="email"
                        id={formId ? `${formId}.email` : 'email'}
                        name="email"
                        autoComplete="email"
                        label={intl.formatMessage({
                          id: 'SignupForm.emailLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.emailPlaceholder',
                        })}
                        validate={validators.composeValidators(emailRequired, emailValid)}
                      />
                    </div>
                    <div className={css.functionButtons}>
                      <Button
                        type="button"
                        onClick={() => {
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'four' });
                        }}
                        className={css.singleBackBtn}
                      >
                        Back
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'six' });
                        }}
                        className={css.singleNextBtn}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                ) : openLandingModal.modalNumber == 'six' ? (
                  <div>
                    <div>
                      <h4 className={css.modalTitle}>Your details is safe with us.</h4>
                      <p className={css.subtitle}>
                        Some matches prefer to provide quotes over the phone to get more details.
                      </p>
                    </div>
                    <div className={css.phoneWrapper}>
                      <div className={css.phoneLabel}>Phone number</div>
                      <PhoneInput
                        className={css.phoneBox}
                        name="phoneNumber"
                        id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                        country={'us'}
                        value={phoneNumber.value}
                        onChange={handleOnChange}
                      />
                    </div>
                    <div className={css.validateText}>
                      {checkPhoneNumber
                        ? 'The phone number is already in use. Please try a different phone number.'
                        : null}
                    </div>
                    <FieldTextInput
                      className={css.password}
                      type="text"
                      id={formId ? `${formId}.fullName` : 'fullName'}
                      name="fullName"
                      autoComplete="given-name"
                      label={intl.formatMessage({
                        id: 'SignupForm.fullNameLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.fullNamePlaceholder',
                      })}
                      validate={validators.required(
                        intl.formatMessage({
                          id: 'SignupForm.fullNameRequired',
                        })
                      )}
                    />
                    <FieldTextInput
                      className={css.password}
                      type="password"
                      id={formId ? `${formId}.password` : 'password'}
                      name="password"
                      autoComplete="new-password"
                      label={intl.formatMessage({
                        id: 'SignupForm.passwordLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.passwordPlaceholder',
                      })}
                      validate={passwordValidators}
                    />
                    <div className={css.venderError}>{loginOrSignupError}</div>
                    <div className={css.termsAndConditions}>{termsAndConditions}</div>
                    <div className={css.buttonGroups}>
                      <Button
                        type="button"
                        onClick={() => {
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'five' });
                        }}
                        className={css.backButton}
                      >
                        Back
                      </Button>
                      <Button
                        type="button"
                        disabled={
                          !(
                            values?.phoneNumber?.length > 10 &&
                            values?.fullName &&
                            values?.password &&
                            values?.terms?.length &&
                            !checkPhoneNumber
                          )
                        }
                        onClick={handleSignupUser}
                      >
                        {(signupInProgress && !signupSuccess) || otpInProgress || fetchVendorsInProgress || updateInProgress || loginInProgress ? (
                          <IconSpinner />
                        ) : (
                          'Next'
                        )}
                      </Button>
                    </div>
                  </div>
                ) : openLandingModal.modalNumber == 'seven' ? (
                  <div>
                    <div>
                      <div className={css.bodyContent}>
                        <div className={css.otpVerification}>
                          <FormattedMessage id="AuthenticationPage.otpVerification" />
                        </div>
                        <label className={css.labelOtp}>
                          <FormattedMessage id="AuthenticationPage.pleaseEnterDigitOtp" />
                        </label>
                        <div className={css.optInputWrapper}>
                          {otp.map((value, index) => (
                            <input
                              key={index}
                              type="text"
                              maxLength="1"
                              value={value}
                              onChange={e => handleChange(index, e.target.value)}
                              ref={input => (otpFields.current[index] = input)}
                              onKeyDown={e => handleKeyPress(index, e)}
                            />
                          ))}
                        </div>
                      </div>
                      <div className={css.otpTimer}>
                        <span className={css.secondRemain}>
                        {otpTimer > 0 ? otpTimer + ' ' + 'seconds remaining' : null}{' '}
                        </span>
                        <span>
                          <div
                            className={css.resendLine}
                            type="button"
                            onClick={e => {
                              getOtpSignup({ phoneNumber: vendorPhoneNumber });
                            }}
                          >
                            Didn't receive OTP ? Resend OTP
                          </div>
                        </span>
                      </div>
                      <Button
                        type="button"
                        className={css.submitOtp}
                        onClick={e => {
                          onOtpSignUpCheck({
                            phoneNumber: values?.phoneNumber,
                            otp: otp?.join(''),
                            userId: currentUser?.id?.uuid,
                          });
                        }}
                        disabled={verifyOtp}
                      >
                        {otpCheckInProgress ? <IconSpinner /> : 'Submit OTP'}
                      </Button>
                    </div>
                    <div className={css.otpText}>
                      {verifyOtp ? (
                        <div className={css.otpVerified}>OTP verified Successfully</div>
                      ) : null}
                      {verifyOtpError ? (
                        <div className={css.invalidOtp}>Invalid OTP Please try again</div>
                      ) : null}
                    </div>
                    <div className={css.actionButtons}>
                      <Button
                        type="button"
                        onClick={() => {
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'six' });
                        }}
                        className={css.backButton}
                      >
                        Back
                      </Button>
                      <Button
                        type="button"
                        disabled={!verifyOtp}
                        onClick={async e => {
                          setOpenLandingModal({ ...openLandingModal, modalNumber: 'eight' });
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                ) : openLandingModal.modalNumber == 'eight' ? (
                  <div>
                    <MainPanelLanding currentUser={currentUser} filteredData={filteredData} />
                  </div>
                ) : null}
              </div>
            </Modal>
          </Form>
        );
      }}
    />
  );
};

LandingModalFormComponent.defaultProps = {
  inProgress: false,
};

LandingModalFormComponent.propTypes = {
  inProgress: bool,
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  appConfig: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LandingModalForm = compose(injectIntl)(LandingModalFormComponent);
LandingModalForm.displayName = 'SignupForm';

export default LandingModalForm;
