import React, { useState } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import { Button, Modal, NamedLink } from '../../../../components';
import LandingModalForm from './LandingModalForm/LandingModalForm';
import { useConfiguration } from '../../../../context/configurationContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { USER_TYPE_CUSTOMER, USER_TYPE_VENDOR } from '../../../../util/types';
import { isSignupEmailTakenError } from '../../../../util/errors';
import TermsAndConditions from '../../../AuthenticationPage/TermsAndConditions/TermsAndConditions';
import { TermsOfServiceContent } from '../../../TermsOfServicePage/TermsOfServicePage';
import { camelize } from '../../../../util/string';
import { PRIVACY_POLICY_ASSET_NAME, TOS_ASSET_NAME } from '../../../AuthenticationPage/AuthenticationPage.duck';
import { PrivacyPolicyContent } from '../../../PrivacyPolicyPage/PrivacyPolicyPage';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const [openLandingModal, setOpenLandingModal] = useState(false);
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    sectionId,
  } = props;

  const servicesList = options?.subImageContent?.Text;

  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  const intl = useIntl();
  const currentUser = options.currentUser;

  const isVendor =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.userRole == USER_TYPE_VENDOR;

  const config = useConfiguration();

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(options.signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );
  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = errorMessage(options.signupError, signupErrorMessage);

  const handleSubmitSignup = async (values) => {
    const { fullName, serviceType = [], ...rest } = values;

    const restValues =
      serviceType && serviceType.length
        ? { ...rest, serviceType, serviceTypeCategory: serviceType.map(st => st.option) }
        : { ...rest, serviceType };

    const name = fullName.trim().split(' ');
    const params = {
      firstName: name[0],
      lastName: `${name.length > 1 ? name[name.length - 1] : ' '} `,
      userRole: USER_TYPE_CUSTOMER,
      transactionProcessAlias: 'default-purchase/release-1',
      ...restValues,
    };

    try {
      const response = await options.submitSignup(params);
      if (response == 'true') {
        setOpenLandingModal({
          ...openLandingModal, modalNumber: 'seven'
        });
      }
    } catch (error) {
      console.log('signup failed');
    }
  };

  return (
    <div>

      {sectionId == "ideas" ? null :
        <BlockContainer
          id={blockId}
          className={classes}
          onClick={e => {
            !isVendor ? setOpenLandingModal({ title: title, modalNumber: "one", currentModalId: blockId }) : {};
          }}
        >
          <FieldMedia
            media={media}
            sizes={responsiveImageSizes}
            className={mediaClassName}
            options={options}
          />
          {hasTextComponentFields ? (
            <div className={blockId === "about-description" ? css.aboutDescription : classNames(textClassName, css.text)}>
              <Field data={title} options={options} />
              <Field data={text} options={options} />
              <Field data={callToAction} className={ctaButtonClass} options={options} />
            </div>
          ) : null}
          {blockId == 'description' && <NamedLink className={css.buttonExplore} name="LandingPage"><Button >Explore Vendors</Button></NamedLink>}
        </BlockContainer>}

      {
        sectionId == "ideas"
        && <></>
        // <LandingModalForm
        //   media={media}
        //   sizes={responsiveImageSizes}
        //   onSubmit={values => { }}
        //   initialValues={null}
        //   isMobile={false}
        //   appConfig={config}
        //   options={options}
        //   openLandingModal={openLandingModal}
        //   setOpenLandingModal={setOpenLandingModal}
        //   onSubmitLandingSearchFilterForm={values => handleSubmitSignup(values)}
        //   currentUser={options.currentUser}
        //   isAuthenticated={options.isAuthenticated}
        //   signupInProgress={options.signupInProgress}
        //   signupSuccess={options.signupSuccess}
        //   onCustomerListing={options.onCustomerListing}
        //   onManageDisableScrolling={options.onManageDisableScrolling}

        //   termsAndConditions={
        //     <TermsAndConditions
        //       onOpenTermsOfService={() => setTosModalOpen(true)}
        //       onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
        //       intl={intl}
        //     />
        //   }
        //   loginOrSignupError={loginOrSignupError}
        // />
      }
    </div >

  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
