import React, { useEffect, useState } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import Collapsible from 'react-collapsible';


import css from './SectionContainer.module.css';
import { getStrapiFaqContent } from '../../../../util/api';
import Slider from "react-slick";
const FaqPage = props => {
    const { className } = props;
    const [faqContent, setFaqContent] = useState(false);

    useEffect(() => {
        fetchFaqContent();
    }, []);

    const fetchFaqContent = async () => {
        try {
            const res = await getStrapiFaqContent({});
            setFaqContent(res?.data?.attributes?.ImageAndVideo?.data ?? []);
        } catch (err) {
            console.log(err, '&&& &&& => err');
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div className={css.informationContainer}>
            <div className={css.faqContent}>
                <div className={css.sliderWrapper}>
                    <div className={css.slideVideo}>
                    {faqContent && faqContent !== 0 &&
                            faqContent?.map((items, index) => {
                                const url = items?.attributes?.url;
                                if (url) {
                                    return (
                                        <div key={index} className={css.slideVideo}>
                                            <img src={url} />
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <div className={css.faqText}>
                    <Collapsible trigger="What is MyFindor?">
                        <p>MyFindor is a specialized lead generation platform catering to South Asian customers seeking wedding and event vendors. Our focus is on providing a tailored experience that aligns with the unique cultural aspects and needs of the South Asian community</p>
                    </Collapsible>
                    <Collapsible trigger="How does MyFindor differ from other platforms?">
                        <p>
                            MyFindor distinguishes itself by understanding and valuing South Asian traditions. We aim to be the leading resource for discovering vendors who appreciate and incorporate these cultural elements into their services.
                        </p>
                    </Collapsible>
                    <Collapsible trigger="What services are available on MyFindor?">
                        <p>
                            MyFindor offers a comprehensive range of wedding and event vendors tailored to the South Asian community. From venues and caterers to photographers and decorators, we strive to meet all your event planning needs
                        </p>
                    </Collapsible>
                    <Collapsible trigger="How does MyFindor ensure a personalized experience?">
                        <p>
                            We prioritize personalization by considering the cultural nuances of the South Asian community. Our platform is designed to seamlessly connect users with vendors who not only meet their requirements but also understand and respect their traditions.
                        </p>
                    </Collapsible>
                    <Collapsible trigger="Can vendors on MyFindor be trusted for South Asian events?">
                        <p>
                            Absolutely. We thoroughly vet and select vendors who have experience in South Asian events. Their understanding of traditions ensures that your wedding or event is celebrated in a culturally rich and meaningful way.
                        </p>
                    </Collapsible>
                    <Collapsible trigger="How do I get started as a user on MyFindor?">
                        <p>
                            Simply sign up on MyFindor and customize your search based on your event needs and preferences to discover the perfect vendors for your special day.

                        </p>
                    </Collapsible>
                    <Collapsible trigger="What information do vendors receive when I reach out to them?">
                        <p>
                            Vendors receive your contact details and event preferences when you reach out. This ensures a direct and efficient connection between you and the vendors who can best fulfill your requirements.
                        </p>
                    </Collapsible>
                    <Collapsible trigger="How does MyFindor support vendor growth?">
                        <p>
                            MyFindor provides vendors with a platform to showcase their services to a targeted audience. By joining as a vendor, you gain access to our community and receive hot, live leads that can contribute to the growth of your business.
                        </p>
                    </Collapsible>
                    <Collapsible trigger="Is there a fee for using MyFindor?">
                        <p>
                            Absolutely, using MyFindor is entirely free for customers. There are no fees associated with signing up, exploring vendors, or connecting with vendors who can help make your special events memorable. Enjoy the convenience and benefits of our platform without any cost to you.
                        </p>
                    </Collapsible>
                    <Collapsible trigger=" What if I have specific questions or need assistance?">
                        <p>
                            For any inquiries or assistance, feel free to reach out to our dedicated support team. We are here to help make your experience on MyFindor as smooth and enjoyable as possible. Please email us at info@myfindor.com
                        </p>
                    </Collapsible>
                </div>
            </div>
        </div>
    );
};

const propTypeOption = shape({
    fieldComponents: shape({ component: node, pickValidProps: func }),
});

FaqPage.defaultProps = {
    rootClassName: null,
    className: null,
    as: 'section',
    children: null,
    appearance: null,
};

FaqPage.propTypes = {
    rootClassName: string,
    className: string,
    as: string,
    children: node,
    appearance: object,
    options: propTypeOption,
};

export default FaqPage;

