import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { LinkedLogo, NamedLink } from '../../../../components';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';
import AppStore from '../../../../assets/image/appstore.png';
import PlayStore from '../../../../assets/image/googleplay.png';
import {  useSelector } from 'react-redux';

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    currentUserOptions,
  } = props;
   const currentUser = useSelector(state=>state?.user?.currentUser)
  const {publicData} = currentUser?.attributes?.profile ?? ''
  const userRole = publicData?.userRole ?? ''
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;

  // use block builder instead of mapping blocks manually

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.footer}>
        <div className={classNames(css.content, getContentCss(numberOfColumns))}>
          <div>
            <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
            />
          </div>
          <div className={css.sloganMobile}>
            <Field data={slogan} className={css.slogan} />
          </div>
          <div className={css.detailsInfo}>
            <div className={css.sloganDesktop}>
              <Field data={slogan} className={css.slogan} />
            </div>
            <div className={css.Applinks}>
              <a href='https://apps.apple.com/in/app/myfindor-weddings-events/id6651836569'>
                <img src={AppStore} alt='app store' />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.myfindor'>
                <img src={PlayStore} alt='play store' />
              </a>
            </div>
            {showSocialMediaLinks ? (
              <div className={css.icons}>
                <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
              </div>
            ) : null}

            <Field data={copyright} className={css.copyright} />
          </div>
          <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
            <div className={css.gridBlockBox}>
              <h3>For Customers</h3>
              <ul>
                <li>
                  <NamedLink name="LandingPage">
                    Find vendors
                  </NamedLink>
                </li>
                <li>
                  <a href="/p/how-it-works">
                    How it works
                  </a>
                </li>
                <li>
                  <a href="/Login">
                    Login
                  </a>
                </li>
              </ul>
            </div>
            <div className={css.gridBlockBox}>
              <h3>For Vendors </h3>
              <ul>
                <li>
                  <a href="/p/pricing">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="/signup">
                    Join as a Vendor
                  </a>
                </li>
                <li>
                  <a href="/p/how-it-works-vendor">
                    How it works
                  </a>
                </li>
                {userRole === 'vendor' &&(
                <li>
                  <NamedLink name="StripeSubscriptionPage">
                    Buy Subscription
                  </NamedLink>
                </li>
                )}
                {userRole === 'vendor' &&(
                <li>
                  <NamedLink name="ReferralCodePage">
                    Refer a Friend
                  </NamedLink>
                </li>
                )}
              </ul>
            </div>
            <div className={css.gridBlockBox}>
              <h3>About MyFindor</h3>
              <ul>
                <li>
                  <a href="/p/about">
                  About us
                  </a>
                </li>
                <li>
                  <a href="/p/faqs">
                  FAQ’s
                  </a>
                </li>
                <li>
                  <a href="/contact-us">
                    Contact us
                  </a>
                </li>
                <li>
                  <a href="/terms-of-service">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            {/* <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} /> */}
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
  currentUserOptions: null
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
  currentUserOptions: propTypeOption,
};

export default SectionFooter;
