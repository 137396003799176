import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import Collapsible from 'react-collapsible';


import css from './SingleSliderLoader.module.css';

const SingleSliderLoader = props => {

    return (
        <div className={css.singleSliderWrapper}>
            <div className={css.videoLoader} />
        </div>
    );
};


SingleSliderLoader.defaultProps = {
    className: null,
};

SingleSliderLoader.propTypes = {
    className: string,
};

export default SingleSliderLoader;

