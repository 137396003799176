import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import css from './SearchPage.module.css';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { intlShape } from '../../util/reactIntl';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { Button, FieldTextInput } from '../../components';

class MainPanelLandingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSecondaryFiltersOpen: false,
            currentQueryParams: { ...props.urlQueryParams,},
            category:''
        };
        this.handleSearch = this.handleSearch.bind(this);
    }


    handleSearch = (e) => {
        const {filteredData} = this.props;

        
        
        this.setState({
            filteredData: filteredData,
          currentQueryParams: {
            ...this.state.currentQueryParams,
            "pub_category":`has_any:${filteredData}`,
          }
        })
      }           
    componentDidMount() {
        this.handleSearch()
      }

    render() {
        const {
            intl,
            routeConfiguration,
            history,
            filteredData,
        } = this.props;
        
        
     
        return (
            <div className={css.searchBarHero}>
                <div>
                    <p className={css.modalSuccessCenter}><svg width="20px" height="20px" viewBox="0 0 0.8 0.8" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="icomoon-ignore" /><path d="M0.4 0.067C0.216 0.067 0.067 0.216 0.067 0.4S0.216 0.733 0.4 0.733c0.184 0 0.333 -0.149 0.333 -0.333S0.584 0.067 0.4 0.067zm0 0.64C0.231 0.707 0.093 0.569 0.093 0.4S0.231 0.093 0.4 0.093c0.169 0 0.307 0.138 0.307 0.307S0.569 0.707 0.4 0.707z" fill="#000000" /><path d="m0.567 0.281 -0.214 0.207 -0.075 -0.075a0.02 0.02 0 0 0 -0.028 0.028l0.089 0.089a0.02 0.02 0 0 0 0.028 0l0.228 -0.221a0.02 0.02 0 0 0 -0.028 -0.029z" fill="#000000" /></svg> We've posted your request</p>
                    {/* <h5 className={css.modalTextCenter}>Describe your request in details</h5>
                    <p className={css.subtitle}>Add more details to get faster and more accurate qoutes</p> */}
                </div>
                {/* <div>
                    <FieldTextInput
                        id={"description"}
                        name="description"
                        className={css.descriptionInput}
                        type="textarea"
                        label={intl.formatMessage({ id: 'EditListingDetailsForm.description' })}
                        placeholder={intl.formatMessage({
                            id: 'EditListingDetailsForm.descriptionPlaceholder',
                        })}
                    />
                </div> */}
                <br/>
                <div className={css.viewButton}>
                    <Button
                        className={css.viewMatchBtn}
                        onClick={() => {
                            history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, this.state.currentQueryParams));
                        }}
                    >
                        View leads
                    </Button>
                </div>
            </div>
        );
    }
}

MainPanelLandingComponent.defaultProps = {
    listings: [],
    pagination: null,
    searchListingsError: null,
    searchParams: {},
    tab: 'listings',
    activeListingId: null,
};

MainPanelLandingComponent.propTypes = {
    listings: array,
    onActivateListing: func.isRequired,
    onManageDisableScrolling: func.isRequired,
    pagination: propTypes.pagination,
    scrollingDisabled: bool.isRequired,
    searchInProgress: bool.isRequired,
    searchListingsError: propTypes.error,
    searchParams: object,
    tab: oneOf(['filters', 'listings', 'map']).isRequired,

    // from useHistory
    history: shape({
        push: func.isRequired,
    }).isRequired,
    // from useLocation
    location: shape({
        search: string.isRequired,
    }).isRequired,

    // from useIntl
    intl: intlShape.isRequired,

    // from useConfiguration
    config: object.isRequired,

    // from useRouteConfiguration
    routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
    const config = useConfiguration();
    const routeConfiguration = useRouteConfiguration();
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();

    return (
        <MainPanelLandingComponent
            config={config}
            routeConfiguration={routeConfiguration}
            intl={intl}
            history={history}
            location={location}
            {...props}
        />
    );
};

const mapStateToProps = state => {
    const {
        currentPageResultIds,
        pagination,
        searchInProgress,
        searchListingsError,
        searchParams,
        activeListingId,
    } = state.SearchPage;
    const listings = getListingsById(state, currentPageResultIds);

    return {
        listings,
        pagination,
        scrollingDisabled: isScrollingDisabled(state),
        searchInProgress,
        searchListingsError,
        searchParams,
        activeListingId,
    };
};

const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const MainPanelLanding = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(EnhancedSearchPage);

export default MainPanelLanding;
