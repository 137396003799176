import React, { useState } from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionColumns.module.css';
import Slider from 'react-slick';
import { IconCard, IconSpinner, Modal } from '../../../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfiguration } from '../../../../context/configurationContext';
import { isSignupEmailTakenError } from '../../../../util/errors';
import LandingModalForm from '../../BlockBuilder/BlockDefault/LandingModalForm/LandingModalForm';
import TermsAndConditions from '../../../AuthenticationPage/TermsAndConditions/TermsAndConditions';
import { USER_TYPE_CUSTOMER, USER_TYPE_VENDOR } from '../../../../util/types';
import { TermsOfServiceContent } from '../../../TermsOfServicePage/TermsOfServicePage';
import { PrivacyPolicyContent } from '../../../PrivacyPolicyPage/PrivacyPolicyPage';
import { camelize } from '../../../../util/string';
import {
  PRIVACY_POLICY_ASSET_NAME,
  TOS_ASSET_NAME,
} from '../../../AuthenticationPage/AuthenticationPage.duck';
import MultiSliderLoader from './MultiSliderLoader/MultiSliderLoader';
import { categories } from '../../../../config/configData';
// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 265px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <IconCard brand="next" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <IconCard brand="prev" />
    </div>
  );
}
const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionColumns = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    mediaClassName,
    blockId,
    responsiveImageSizes,
    text,
    media,
  } = props;
  const data = options?.combinedArray;
 
  const servicesList = options?.subImageContent?.Text;
  const [openLandingModal, setOpenLandingModal] = useState(false);

  const popularServicesHeading = options?.subImageContent;

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const isLoading = options?.isVideoSubloading;

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  const intl = useIntl();
  const currentUser = options.currentUser;

  const isVendor =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.userRole == USER_TYPE_VENDOR;

  const config = useConfiguration();
  const [isModalOpen, setIsOpenModal] = useState(false);
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(options.signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );
  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = errorMessage(options.signupError, signupErrorMessage);

  const handleSubmitSignup = async values => {
    const { fullName, ...rest } = values;
    const serviceType = categories.filter(st => st.option == openLandingModal?.title);
    const restValues =
      serviceType && serviceType.length
        ? { ...rest, serviceType, serviceTypeCategory: serviceType.map(st => st.option) }
        : { ...rest, serviceType };

    const name = fullName.trim().split(' ');
    const params = {
      firstName: name[0],
      lastName: `${name.length > 1 ? name[name.length - 1] : ' '} `,
      userRole: USER_TYPE_CUSTOMER,
      transactionProcessAlias: 'default-purchase/release-1',
      ...restValues,
    };
    return options.submitSignup(params).then(response => {
      return response;
    });
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: 6,
    variableWidth: false,
    slidesToScroll: 3,
    // initialSlide: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
      popularServicesHeading={popularServicesHeading}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {sectionId === 'ideas' && (
        <div>
          <div className={css.ideaBox}>
            <h2>{popularServicesHeading?.Heading}</h2>
            <p>{popularServicesHeading?.SubHeading}</p>
          </div>
          <div className={css.servicesGrid}>
            {servicesList?.map((item, i) => {
              return (
                <div
                  className={css.ideaGrid}
                  key={i}
                  onClick={e => {
                    !isVendor
                      ? setOpenLandingModal({
                          title: item?.Text,
                          modalNumber: 'one',
                          currentModalId: blockId,
                          image: item?.Image?.data?.[0]?.attributes?.url,
                        })
                      : {};
                  }}
                >
                  <img src={item?.Image?.data?.[0]?.attributes?.url} />
                  <h3 className={css.servicesHeading}>{item?.Text}</h3>
                </div>
              );
            })}
          </div>
          {sectionId == 'ideas' && (
            <LandingModalForm
              //  media={media}
              backgroundImage={openLandingModal?.image}
              sizes={responsiveImageSizes}
              onSubmit={values => {}}
              initialValues={null}
              isMobile={false}
              appConfig={config}
              options={options}
              openLandingModal={openLandingModal}
              setOpenLandingModal={setOpenLandingModal}
              onSubmitLandingSearchFilterForm={values => handleSubmitSignup(values)}
              currentUser={options.currentUser}
              isAuthenticated={options.isAuthenticated}
              signupInProgress={options.signupInProgress}
              signupSuccess={options.signupSuccess}
              onCustomerListing={options.onCustomerListing}
              onManageDisableScrolling={options.onManageDisableScrolling}
              onOtpSignUpCheck={options.onOtpSignUpCheck}
              getOtpSignup={options.getOtpSignup}
              termsAndConditions={
                <TermsAndConditions
                  onOpenTermsOfService={() => setTosModalOpen(true)}
                  onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
                  intl={intl}
                />
              }
              loginOrSignupError={loginOrSignupError}
              otpData={options.otpData}
              otpInProgress={options.otpInProgress}
              otpCheckData={options.otpCheckData}
              otpCheckError={options.otpCheckError}
              doesPhoneExist={options.doesPhoneExist}
              onCheckPhoneNumber={options.onCheckPhoneNumber}
              otpCheckInProgress={options.otpCheckInProgress}
              onUpdateProfile={options.onUpdateProfile}
              updateInProgress={options.updateInProgress}
              loginInProgress={options.loginInProgress}
            />
          )}
        </div>
      )}
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={() => {}}
      >
        <div className={css.termsWrapper}>
          <TermsOfServiceContent
            inProgress={options.tosFetchInProgress}
            error={options.tosFetchError}
            data={options.tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
      <Modal
        id="AuthenticationPage.privacyPolicy"
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        usePortal
        onManageDisableScrolling={() => {}}
      >
        <div className={css.privacyWrapper}>
          <PrivacyPolicyContent
            inProgress={options.tosFetchInProgress}
            error={options.tosFetchError}
            data={options.tosAssetsData?.[camelize(PRIVACY_POLICY_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>

      {hasBlocks && sectionId != 'ideas' ? (
        <div
          className={
            sectionId === 'benefits'
              ? css.benefitWrapper
              : classNames(defaultClasses.blockContainer, getColumnCSS(numColumns), {
                  [css.noSidePaddings]: isInsideContainer,
                })
          }
        >
          {sectionId === 'benefits' ? 
          ( 
          <div className={css.benefitSlider}>
              <div className={css.videoAndHeadingWrapper}>
                {isLoading ? (
                  // <IconSpinner type={true} />
                  <MultiSliderLoader />
                ) : (
                  <>
                    <div className={css.desktopSlider}>
                      {data?.length !== 0 &&
                        data?.map((option, index) => {
                          const url = option?.attributes?.url;
                          const heading = option?.Heading;
                          const subHeading = option?.SubHeading;
                          const displayNumber = Math.floor(index / 2) + 1;
                          
                          if (index !== data.length ) {
                            if (url) {
                              return (
                                <div key={index} className={css.slideVideo}>
                                  <video 
                                  autoPlay 
                                  loop 
                                  muted 
                                  playsInline name="media" 
                                  webkit-playsinline="true"
                                  controls={false}>
                                    <source src={url} type={option?.attributes?.mime} />
                                  </video>
                                </div>
                              );
                            } else {
                              return (
                                <div key={index} className={css.slideData}>
                                  <span className={css.indexCount}>
                                  {index % 2 == 1 ? displayNumber : null}
                                    </span>
                                  <div className={css.slideHeading}>{heading}</div>
                                  <div className={css.slideSubHeading}>{subHeading}</div>
                                </div>
                              );
                            }
                          } else {
                            return null;
                          }
                        })}
                    </div>
                    <div className={css.mobileSlider}>
                      {data?.length !== 0 &&
                        data?.map((option, index) => {
                          const url = option?.attributes?.url;
                          const heading = option?.Heading;
                          const subHeading = option?.SubHeading;

                          if (index !== data.length) {
                            if (url) {
                              return (
                                <div key={index} className={css.slideVideo}>
                                  <video 
                                  autoPlay 
                                  loop 
                                  muted 
                                  playsInline name="media"
                                  webkit-playsinline="true"
                                  controls={false}>
                                    <source src={url} type={option?.attributes?.mime} />
                                  </video>
                                </div>
                              );
                            } else {
                              return (
                                <div key={index} className={css.slideData}>
                                  <span className={css.indexCount}>{option?.id}</span>
                                  <div className={css.slideHeading}>{heading}</div>
                                  <div className={css.slideSubHeading}>{subHeading}</div>
                                </div>
                              );
                            }
                          } else {
                            return null;
                          }
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <BlockBuilder
              ctaButtonClass={defaultClasses.ctaButton}
              blocks={blocks}
              sectionId={sectionId}
              responsiveImageSizes={getResponsiveImageSizes(numColumns)}
              options={options}
            />
          )}
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionColumns.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  mediaClassName: null,
  description: null,
  appearance: null,
  callToAction: null,
  media: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionColumns.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  options: arrayOf(object),
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  mediaClassName: string,
  title: object,
  description: object,
  media: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionColumns;
