import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import Collapsible from 'react-collapsible';


import css from './MultiSliderLoader.module.css';

const MultiSliderLoader = props => {

    return (
        <div className={css.multiSliderWrapper}>
            <div className={css.contentLoader}>
                <div className={css.heading} />
                <div className={css.subHeading} />
            </div>
            <div className={css.videoLoader} />
            <div className={css.videoLoader} />
            <div className={css.contentLoader}>
                <div className={css.heading} />
                <div className={css.subHeading} />
            </div>
            <div className={css.contentLoader}>
                <div className={css.heading} />
                <div className={css.subHeading} />
            </div>
            <div className={css.videoLoader} />
        </div>
    );
};


MultiSliderLoader.defaultProps = {
    className: null,
};

MultiSliderLoader.propTypes = {
    className: string,
};

export default MultiSliderLoader;

