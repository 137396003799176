import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import Collapsible from 'react-collapsible';
import Field from '../../Field';
import Slider from "react-slick";

// import HeroImg1 from '../../../../assets/image/heroImg.jpg'
// import HeroImg2 from '../../../../assets/image/heroImg2.jpg'
// import HeroImg3 from '../../../../assets/image/heroImg3.jpg'
// import HeroImg4 from '../../../../assets/image/heroImg4.jpg'

import css from './SectionContainer.module.css';
import FaqPage from './FaqPage';

// This component can be used to wrap some common styles and features of Section-level components.
// E.g: const SectionHero = props => (<SectionContainer><H1>Hello World!</H1></SectionContainer>);
const SectionContainer = props => {
  const { className, rootClassName, id, as, children, appearance, options, popularServicesHeading, ...otherProps } = props;
  const Tag = as || 'section';

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {

          dots: false
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      },
    ]
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Tag className={classes} id={id} {...otherProps}>
      {appearance?.fieldType === 'customAppearance' ? id !== "landing-hero" && (
        <Field
          data={{ alt: `Background image for ${id}`, ...appearance }}
          className={className}
          options={options}
        />
      ) : null}
      <div className={
        id === "ideas" ? css.ideaWrapper :
          id === "image-section" ? css.aboutImageSection :
            css.sectionContent}>
        {children}
      </div>
      {id == "faq-hero" &&
        <div>
          <FaqPage />
        </div>}
    </Tag>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionContainer.defaultProps = {
  rootClassName: null,
  className: null,
  as: 'section',
  children: null,
  appearance: null,
};

SectionContainer.propTypes = {
  rootClassName: string,
  className: string,
  as: string,
  children: node,
  appearance: object,
  options: propTypeOption,
};

export default SectionContainer;
